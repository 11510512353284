.Home {
}

.Home-header-icon {
  color: #ff5722
}

.Home-border {
  border-color: #ff5722;
  border-width: 2px;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.03);
}